import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Text, Box, Flex } from "rebass/styled-components";
import Fade from "react-reveal/Fade";
import { Content, Title, Info, BtnGroup, Btn } from "./style";
import { Container } from "../../../global";

import Banner1 from "../../../../images/apply-now/banner1.png";
import Banner2 from "../../../../images/apply-now/banner2.png";
import Banner3 from "../../../../images/apply-now/banner3.png";
const Paragraph = props => (
  <Text
    variant="body"
    mb="2rem"
    as="p"
    fontSize={["sm", null, "sm", null, "sm"]}
    textAlign="left"
    style={{ textDecoration: "none" }}
    {...props}
  />
);

const OutOfSchool = () => {
  return (
    <Container
      position="relative"
      style={{ width: "100vw", maxWidth: "100vw", padding: "0", background: "rgb(244, 252, 250);" }}
    >
      <Content bc="#fff">
        <Flex
          justifyContent="space-between"
          marginBottom={["20px", "20px", "20px", "50px", "50px"]}
          marginTop={["20px", "20px", "20px", "50px", "50px"]}
          flexDirection={["column", "column", "column", "row", "row"]}
        >
          <Box
            maxWidth={["100%", "100%", "100%", "33%", "33%"]}
            marginRight={["0", "0", "0", "100px", "100px"]}
            textAlign={"center"}
          >
            <img width={"100px"} src={Banner1}></img>
            <Paragraph
              color={"rgba(29, 201, 155, 1)"}
              fontSize={["12px", "12px", "12px", "16px", "20px"]}
              marginTop={["10px", "10px", "10px", "20px", "20px"]}
              textAlign={"center!important"}
              maxWidth={["100%", "100%", "100%", "280px", "400px"]}
            >
              To provide free universal primary and secondary education via select NGOs
            </Paragraph>
          </Box>
          <Box
            maxWidth={["100%", "100%", "100%", "33%", "33%"]}
            marginRight={["0", "0", "0", "100px", "100px"]}
            textAlign={"center"}
          >
            <img width={"100px"} src={Banner2}></img>
            <Paragraph
              color={"rgba(29, 201, 155, 1)"}
              fontSize={["12px", "12px", "12px", "16px", "20px"]}
              marginTop={["10px", "10px", "10px", "20px", "20px"]}
              textAlign={"center!important"}
              maxWidth={["100%", "100%", "100%", "280px", "400px"]}
            >
              To provide free universal primary and secondary education via select NGOs
            </Paragraph>
          </Box>
          <Box
            maxWidth={["100%", "100%", "100%", "33%", "33%"]}
            marginRight={["0", "0", "0", "100px", "100px"]}
            textAlign={"center"}
          >
            <img width={"100px"} src={Banner3}></img>
            <Paragraph
              color={"rgba(29, 201, 155, 1)"}
              fontSize={["12px", "12px", "12px", "16px", "20px"]}
              marginTop={["10px", "10px", "10px", "20px", "20px"]}
              textAlign={"center!important"}
              maxWidth={["100%", "100%", "100%", "280px", "400px"]}
            >
              To provide free universal primary and secondary education via select NGOs
            </Paragraph>
          </Box>
        </Flex>
      </Content>
      <Content>
        <Title>Out-of-school and vulnerable children in undeserved and low-income communities in Nigeria</Title>
        <Info>
          As part of its responsibility towards sustainable social development, OPay is implementing 2023 CSR project
          ""Reduction in the number of out-of-school and disadvantaged children in under-served and low-income
          communities across Nigeria” which is a multi-year education project funded by OPay Digital Services Ltd.
          Through the use of cutting-edge digital technology, the project aims to help Nigeria's under served and
          low-income communities reduce the number of disadvantaged and out-of-school children in line with global
          sustainability initiatives. The promotion of opportunities for lifelong learning for all and the assurance of
          inclusive quality education are two project results areas that align with UNICEF's Sustainable Development
          Goal 4, which the organization hopes to accomplish by 2030.
        </Info>
      </Content>
    </Container>
  );
};

export default OutOfSchool;
