import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation-new";
import Header from "../components/sections/apply-now/header/header";
import Content from "../components/sections/apply-now/content/content";
import OutOfSchool from "../components/sections/apply-now/outOfSchool/outOfSchool";
import Footer from "../components/sections/footer/index-new";
const csr = () => (
  <Layout>
    <SEO title="OPay" />
    <Navigation background="white" />
    <Header />
    <Content></Content>
    <OutOfSchool />
    <Footer />
  </Layout>
);

export default csr;
