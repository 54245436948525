import Img from "gatsby-image";
import styled from "styled-components";

const StyledHeaderBg = styled(Img)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
`;

const ContentContainer = styled.div`
  ${({ theme }) => theme.mq.sm`
    max-width: 540px;
    position: static;
    text-align: center;
    margin: 10px auto;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
  `};

  ${({ theme }) => theme.mq.md`
    max-width: 720px;
    text-align: center;
    margin: 20px auto;
  `};

  ${({ theme }) => theme.mq.lg`
    max-width: 1200px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
  `};

  ${({ theme }) => theme.mq.xl`
    max-width: 1200px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
  `};

  ${props =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};
`;

const DataContent = styled.div`
  padding: 20px;
  background: rgba(33, 15, 96, 1);
  margin-top: -210px;
  position: relative;
  z-index: 10;
  display: flex;
  ${({ theme }) => theme.mq.md`
      margin-top: -80px;
    `};
`;
const DataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const ItemLabel = styled.div`
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  padding: 0 20px 0 0;
  min-width: 80px;
  text-align: right;
  ${({ theme }) => theme.mq.md`
    font-size: 48px;
    min-width: 170px;
    text-align: right;
  `};
`;

const ItemValue = styled.div`
  font-size: 12px;
  color: rgba(29, 201, 155, 1);
  padding-left: 20px;
  border-left: 1px solid #fff;

  ${({ theme }) => theme.mq.md`
    font-size: 20px;
  `};
`;

export { StyledHeaderBg, StyledContainer, ContentContainer, DataContent, DataItem, ItemLabel, ItemValue };
