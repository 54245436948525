import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Text } from "rebass/styled-components";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";
import {
  ServicesContainer,
  NewContainerBox,
  Flex,
  SubTitle,
  Content,
  Img,
  CommonContainer,
  Footer,
  Btn,
} from "./style";
import { Container } from "../../../global";

import girl from "../../../../images/apply-now/girl.png";
import boy from "../../../../images/apply-now/boy.png";
import process from "../../../../images/apply-now/process.png";
import people from "../../../../images/apply-now/people.png";
import btn from "../../../../images/apply-now/btn.png";
import smile from "../../../../images/apply-now/smile.png";

const Paragraph = props => (
  <Text
    variant="body"
    mb="2rem"
    as="p"
    fontSize={["sm", null, "sm", null, "sm"]}
    textAlign="left"
    style={{ textDecoration: "none" }}
    {...props}
  />
);

const OutOfSchool = () => {
  const data = useStaticQuery(graphql`
    query {
      outOfSchool: file(sourceInstanceName: { eq: "product" }, name: { eq: "outOfSchool" }) {
        childImageSharp {
          fluid(maxWidth: 533) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container position="relative" style={{ width: "100vw", maxWidth: "100vw", padding: "0" }}>
      <ServicesContainer>
        <Flex direction="reverse" overflow="hidden" style={{ paddingLeft: "5.69vw" }}>
          <NewContainerBox></NewContainerBox>
          <CommonContainer width="41.7vw" pl="0" ml="2.3vw">
            <Fade bottom delay={250}>
              <SubTitle color="#FFFFFF !important">Eligibility Criteria</SubTitle>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="16px">
                <Paragraph mt="40px" paddingLeft="20px" color="#FFFFFF !important">
                  <ol style={{ listStyle: "disc" }}>
                    <li>
                      <Text
                        sx={{
                          color: "#FFFFFF",
                          fontSize: ["12px", null, null, "20px"],
                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Detailed Project Proposal
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "#FFFFFF",
                          fontSize: ["12px", null, null, "20px"],
                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Proof of CAC Certificate
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "#FFFFFF",
                          fontSize: ["12px", null, null, "20px"],
                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Proof of SCUML Certificate
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "#FFFFFF",
                          fontSize: ["12px", null, null, "20px"],
                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Project Budget
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "#FFFFFF",
                          fontSize: ["12px", null, null, "20px"],
                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Project Monitoring and Evaluation Plan
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "#FFFFFF",
                          fontSize: ["12px", null, null, "20px"],
                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Project Work plan
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "#FFFFFF",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "26px"],
                        }}
                      >
                        A copy of the organization's most recently audited financial record
                      </Text>
                    </li>
                  </ol>
                </Paragraph>
              </Content>
            </Fade>
          </CommonContainer>
          <Img>
            <img src={girl} />
          </Img>
        </Flex>
        <Flex overflow="hidden" style={{ paddingLeft: "5.69vw" }}>
          <NewContainerBox style={{ background: "#F4FCFA" }}></NewContainerBox>
          <Img marginLeft="0">
            <img src={boy} />
          </Img>
          <CommonContainer pr="" width="41.7vw" ml="2.3vw">
            <Fade bottom delay={250}>
              <SubTitle
                color="rgba(33, 15, 96, 1) !important"
                width="42vw"
                pl="4.3vw"
                style={{ letterSpacing: "inherit" }}
              >
                Fair & Equitable Partnership
              </SubTitle>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="16px" width="41vw" pl="4.3vw" color="#210F60">
                <Paragraph mt="40px" color="#FFFFFF !important">
                  <Text
                    sx={{
                      color: "rgba(33, 15, 96, 1)",
                      fontSize: ["12px", null, null, "20px"],

                      lineHeight: ["16px", null, null, "33px"],
                      marginTop: ["6px", null, null, "20px"],
                    }}
                  >
                    One of the unique aspects of this call is the expectation that organizations will establish and
                    develop fair and equitable partnerships from the beginning to the end of the project with relevant
                    local stakeholders, involving them as appropriate throughout the project
                  </Text>
                </Paragraph>
              </Content>
            </Fade>
          </CommonContainer>
        </Flex>
        <Flex direction="reverse" overflow="hidden" style={{ paddingLeft: "5.69vw" }}>
          <NewContainerBox></NewContainerBox>
          <CommonContainer width="41.7vw" pl="0" ml="2.3vw">
            <Fade bottom delay={250}>
              <SubTitle color="#FFFFFF !important">Application Process</SubTitle>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="16px">
                <Paragraph mt="40px" paddingLeft="20px" color="#FFFFFF !important">
                  <ol style={{ listStyle: "disc" }}>
                    <li>
                      <Text
                        sx={{
                          color: "#FFFFFF",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Applicants are requested to submit their proposals by clicking on the below application Link at
                        the bottom of this page.
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "#FFFFFF",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Application review process: 3 to 6 weeks after successful proposal submission.
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "#FFFFFF",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        The deadline for proposal submission is 30th of June 2024 by 11:59 pm (Nigerian time).
                      </Text>
                    </li>
                  </ol>
                </Paragraph>
              </Content>
            </Fade>
          </CommonContainer>
          <Img>
            <img src={process} />
          </Img>
        </Flex>
        <Flex overflow="hidden" style={{ paddingLeft: "5.69vw" }}>
          <NewContainerBox style={{ background: "#F4FCFA" }}></NewContainerBox>
          <Img marginLeft="0">
            <img src={people} />
          </Img>
          <CommonContainer pr="" width="41.7vw" ml="2.3vw">
            <Fade bottom delay={250}>
              <SubTitle
                color="rgba(33, 15, 96, 1) !important"
                width="42vw"
                pl="4.3vw"
                style={{ letterSpacing: "inherit" }}
              >
                <Text
                  sx={{
                    color: "rgba(33, 15, 96, 1)",
                    fontSize: ["18px", null, null, "24px"],
                    fontWeight: 700,
                    lineHeight: ["24px", null, null, "30px"],
                    textAlign: ["center", null, null, "left"],
                  }}
                >
                  Related documents for upload: (Click here to download documents template pack)
                </Text>
              </SubTitle>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="16px" width="41vw" pl="4.3vw" color="#210F60">
                <Paragraph mt="40px" paddingLeft="20px" color="rgba(33, 15, 96, 1) !important">
                  <ol style={{ listStyle: "disc" }}>
                    <li>
                      <Text
                        sx={{
                          color: "rgba(33, 15, 96, 1)",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Proof of CAC certificate
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "rgba(33, 15, 96, 1)",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Proof of SCUML Certificate
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "rgba(33, 15, 96, 1)",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Project Budget
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "rgba(33, 15, 96, 1)",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Proof of SCUML Certificate
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "rgba(33, 15, 96, 1)",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Project Budget
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "rgba(33, 15, 96, 1)",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Project Monitoring and Evaluation Plan
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "rgba(33, 15, 96, 1)",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        Project Work plan (self developed)
                      </Text>
                    </li>
                    <li>
                      <Text
                        sx={{
                          color: "rgba(33, 15, 96, 1)",
                          fontSize: ["12px", null, null, "20px"],

                          lineHeight: ["20px", null, null, "40px"],
                        }}
                      >
                        A copy of the organization’s most recently audited financial record
                      </Text>
                    </li>
                  </ol>
                </Paragraph>
              </Content>
            </Fade>
          </CommonContainer>
        </Flex>
        <Flex direction="reverse" overflow="hidden" style={{ paddingLeft: "5.69vw" }}>
          <NewContainerBox></NewContainerBox>
          <CommonContainer width="41.7vw" pl="0" ml="2.3vw">
            <Fade bottom delay={250}>
              <SubTitle color="rgba(33, 15, 96, 1) !important">Inquiries</SubTitle>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="16px">
                <Paragraph mt="40px" color="#FFFFFF !important">
                  <Text
                    sx={{
                      color: "rgba(33, 15, 96, 1)",
                      fontSize: ["12px", null, null, "20px"],

                      lineHeight: ["16px", null, null, "33px"],
                      marginTop: ["6px", null, null, "20px"],
                    }}
                  >
                    For inquiries, contact the Corporate Social Responsibility Team at:
                    <span style={{ fontWeight: "bold" }}>ngcsr@opay-inc.com</span>
                  </Text>
                  <Text
                    sx={{
                      color: "#fff",
                      fontSize: ["12px", null, null, "20px"],
                      fontWeight: "bold",
                      lineHeight: ["16px", null, null, "33px"],
                      marginTop: ["10px", null, null, "50px"],
                    }}
                  >
                    Application Closing Date: 11:59 pm, 30th of June, 2024.
                  </Text>
                </Paragraph>
              </Content>
            </Fade>
          </CommonContainer>
          <Img>
            <img src={smile} />
          </Img>
        </Flex>
        <Footer>
          <Btn onClick={() => window.open("https://forms.gle/R1YZcTgszhwgWWtc6")}>
            <img src={btn}></img>
            <Text
              sx={{
                color: "#fff",
                fontSize: ["12px", null, null, "18px"],
                fontWeight: "bold",
              }}
            >
              Apply Now
            </Text>
          </Btn>
        </Footer>
      </ServicesContainer>
    </Container>
  );
};

export default OutOfSchool;
