import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Text, Box, Heading as InfoHeader, Flex } from "rebass/styled-components";
import {
  StyledHeaderBg,
  StyledContainer,
  ContentContainer,
  DataContent,
  DataItem,
  ItemLabel,
  ItemValue,
} from "./style";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      csrBg: file(sourceInstanceName: { eq: "product" }, name: { eq: "apply" }) {
        childImageSharp {
          fluid(maxWidth: 2880) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Box mt={["70px"]}>
      <StyledContainer>
        <StyledHeaderBg objectFit="contain" fluid={data.csrBg.childImageSharp.fluid}></StyledHeaderBg>
        <ContentContainer>
          <Text padding={["10px", null, null, "19px 31px 34px 60px"]} display="inline-block">
            <Text
              sx={{
                color: "#fff",
                fontSize: ["18px", null, null, "64px"],
                fontWeight: 700,
                lineHeight: ["24px", null, null, "80px"],
                textAlign: ["center", null, null, "center"],
              }}
            >
              Request for Proposal (RFP) at OPay Digital Services Ltd.
            </Text>
            <Text
              sx={{
                color: "#fff",
                fontSize: ["12px", null, null, "24px"],
                fontWeight: 500,
                lineHeight: ["16px", null, null, "33px"],
                marginTop: ["6px", null, null, "20px"],
                textAlign: ["center", null, null, "center"],
              }}
            >
              As a leading Financial Services company, OPay is committed to addressing National societal priorities with
              a keen focus on education.
            </Text>
          </Text>
        </ContentContainer>
      </StyledContainer>
    </Box>
  );
};

export default Header;
